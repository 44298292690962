import { GetStaticPaths } from "next"

import { IMegamenuCategory } from "data/contentData/interfaces/IMegamenuCategory"
import { IContainerCard } from "data/contentData/interfaces/mongodb/IContainerCard"
import { IPage } from "data/contentData/interfaces/mongodb/IPage"

import PageComponent from "Components/Layout/PageComponent/PageComponent"
import { IPagingContext } from "Components/Layout/PageComponent/PageDataProvider"

export default function Page({
    page,
    footer,
    megamenu,
    paging
}: {
    page: IPage
    footer: IContainerCard[]
    megamenu: IMegamenuCategory[]
    paging: IPagingContext | null
}) {
    return (
        <PageComponent
            page={page}
            footer={footer}
            megamenu={megamenu}
            paging={paging}
        />
    )
}

export const getStaticPaths: GetStaticPaths = async () => {
    return {
        paths: ["/"],
        fallback: "blocking" // false or 'blocking'
    }
}

export { getStaticProps } from "Components/Layout/PageComponent/PageComponent"
